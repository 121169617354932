<template>
  <div class="shop-wallet-info">
    <div class="return-price-info">
      <p class="text-center h6">
        45天內店家主動匯款
      </p>
      <p class="text-secondary text-center mb-1" v-if="soldClothCount>0">已售出單品：共{{ soldClothCount }}件</p>

      <p class="text-secondary text-center mb-1">回饋金總額</p>
      <p class="return-price-sum">NTD {{ soldClothPriceSum }}</p>

      <div class="return-price-detail">
        <div class="row justify-content-between mb-0">
          <div class="col-auto">已領</div>
          <div class="col-auto">NTD {{ withdrawSum }}</div>
        </div>

        <div class="row justify-content-between mb-0">
          <div class="col-auto">處理中</div>
          <div class="col-auto">NTD {{ withdrawProcessingSum }}</div>
        </div>

        <div class="row justify-content-between mb-0">
          <div class="col-auto">可提領</div>
          <div class="col-auto">NTD {{ wallet.points - withdrawProcessingSum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapActions } from 'vuex';

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopWalletInfo',
  data() {
    return {
      withdrawList: [],
      sellClothList: [],
    };
  },
  props: {
    wallet: {
      type: Object,
      required: true,
    }
  },
  components: {
  },
  computed: {
    soldClothCount() {
      return this.sellClothList.reduce((sum, item) => {
        if (item.stockStatus === 1) {
          return sum + 1;
        }
        return sum;
      }, 0);
    },
    soldClothPriceSum() {
      return this.sellClothList.reduce((sum, item) => {
        if (item.stockStatus === 1) {
          return sum + item.soldReturn;
        }
        return sum;
      }, 0);
    },
    withdrawSum() {
      return this.withdrawList.reduce((sum, item) => {
        if (item.status === 1) {
          return sum + item.withdrawAmount;
        }
        return sum;
      }, 0);
    },
    withdrawProcessingSum() {
      return this.withdrawList.reduce((sum, item) => {
        if (item.status === 0) {
          return sum + item.withdrawAmount;
        }
        return sum;
      }, 0);
    }
	},
  watch: {
    
  },
  mounted() {
    try {
      this.handleAsyncTask(async () => {
        const withdrawList = await this.$store.dispatch('api/getWalletWithdrawListPromise');
        const sellClothList = await this.$store.dispatch('api/getSellClothListPromise');
        
        withdrawList.filter((w) => {
          return w.businessAccountSerial === this.wallet.businessAccountSerial;
        }).map((w) => {
          this.withdrawList.push(w);
        });
        sellClothList.filter((sc) => {
          return sc.businessAccountSerial === this.wallet.businessAccountSerial;
        }).map((sc) => {
          this.sellClothList.push(sc);
        });
      });
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.shop-wallet-info {
  .return-price-info {
    border-radius: 1.5rem;
    background-color: #FFFAF4;
    padding: 1rem 2rem;
    margin-bottom: 2rem;

    .return-price-sum {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }

    .return-price-detail {
      color: #6F6F6F;
    }
  }
}
</style>