<template>
  <div class="py-4">
    <div class="container" v-if="inited">
      <div v-if="hasToken">
        <div v-if="shopWallet === null">
          <p class="text-center">尚未建立此商店的錢包</p>
        </div>

        <div v-else>
          <div class="row justify-content-between">
            <div class="col-12 col-md-6 col-lg-5">
              <ShopWalletInfo :wallet="shopWallet"></ShopWalletInfo>
            </div>

            <div class="col-12 col-md-6 col-lg-5">
              <ShopWithdrawList :baSerial="baSerial"></ShopWithdrawList>
            </div>
          </div>
        </div>
      </div>
  
      <div v-else>
        <ShopNotLogin></ShopNotLogin>
      </div>
    </div>
  </div>
</template>


<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapState, mapActions, mapGetters } from 'vuex';
import ShopNotLogin from "../../components/loopii/ShopNotLogin.vue";
import ShopWalletInfo from "../../components/loopii/ShopWalletInfo.vue";
import ShopWithdrawList from "../../components/loopii/ShopWithdrawList.vue";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopWalletView',
  data() {
    return {
      inited: false,
      list: [],
    };
  },
  props: {
    baSerial: {
      type: Number,
      required: true,
    }
  },
  components: {
    ShopNotLogin,
    ShopWalletInfo,
    ShopWithdrawList,
  },
  computed: {
		... mapGetters(['hasToken']),
    ... mapState('wallet', ['walletListData']),
    shopWallet() {
      const w = this.walletListData.find((wallet) => {
        return wallet.businessAccountSerial === this.baSerial;
      });
      if (w) {
        return w;
      }
      return null;
    },
	},
  watch: {
    
  },
  mounted() {
    try {
      if (this.hasToken) {
        this.handleAsyncTask(async () => {
          await this.refreshWalletList();
          this.inited = true;
        });
      } else {
        this.inited = true;
      }
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    ...mapActions('wallet', ['refreshWalletList']),
    
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";

</style>