<template>
  <div class="shop-withdraw-list">
    <div class="log-list">
      <label for="" class="font-weight-bold">提領紀錄</label>
      <div v-if="withdrawList.length > 0">
        <WithdrawLogCard v-for="(log) in withdrawList" :key="log.serial" :log="log"></WithdrawLogCard>

      </div>

      <div v-else>
        <p>
          目前尚未有提領紀錄喔！<br/>
          回饋金匯款完成後，相關資訊將會更新並顯示在此。
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import WithdrawLogCard from '../../views/share/WithdrawLogCard.vue';

import { mapActions } from 'vuex';
export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopWithdrawList',
  data() {
    return {
      withdrawList: [],
    };
  },
  props: {
    baSerial: {
      type: Number,
      required: true,
    }
  },
  components: {
    WithdrawLogCard,
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {
    try {
      this.handleAsyncTask(async () => {
        const withdrawList = await this.$store.dispatch('api/getWalletWithdrawListPromise');
        
        withdrawList.filter((w) => {
          return w.businessAccountSerial === this.baSerial;
        }).map((w) => {
          this.withdrawList.push(w);
        });
      });
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.not-login {
  p {
    color: $typography-primary-default;
  }
  .log-list {

  }
}
</style>